import React, { useState } from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import EmailIllustrationSrc from "images/email-illustration.svg";
import sendEmail from "components/api/sendEmail";
// import { setupDirect } from "@testing-library/user-event/dist/types/setup/setup";

const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-20 md:py-24`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-5/12 flex-shrink-0 h-80 md:h-auto`;
const TextColumn = styled(Column)(props => [
  tw`md:w-7/12 mt-16 md:mt-0`,
  props.textOnLeft ? tw`md:mr-12 lg:mr-16 md:order-first` : tw`md:ml-12 lg:ml-16 md:order-last`
]);
const Description = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`
const Image = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded bg-contain bg-no-repeat bg-center h-full`,
]);
const TextContent = tw.div`lg:py-8 text-center md:text-left`;

const Subheading = tw(SubheadingBase)`text-center md:text-left`;
const Heading = tw(SectionHeading)`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;

const Form = tw.form`mt-8 md:mt-10 text-sm flex flex-col max-w-sm mx-auto md:mx-0`
const Input = tw.input`mt-6 first:mt-0 border-b-2 py-3 focus:outline-none font-medium transition duration-300 hocus:border-primary-500`
const Textarea = styled(Input).attrs({as: "textarea"})`
  ${tw`h-24`}
`

const SubmitButton = tw(PrimaryButtonBase)`inline-block mt-8`

const TwoColContactUsWithIllustrationFullForm = ({
  subheading = "Contact Us",
  heading = <>Feel free to <span tw="text-amber-500">get in touch</span><wbr/> with us.</>,
  submitButtonText = "Send",
  textOnLeft = true,
  description = "Got it!, We will be in contact shortly.",
  tryagain = "Please try again.",
}) => {
  // The textOnLeft boolean prop can be used to display either the text on left or right side of the image.
  const [fullName, setFullName] = useState('');
  const [email, setEmail] = useState('');
  const [subject, setSubject] = useState('');
  const [msg, setMsg] = useState('');
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [sentemail, setSentEmail] = useState(false);
  const [sentemailerror, setSentEmailError] = useState(false);

  const isValid = () => {
    var regEmail = /\S+@\S+\.\S+/;
    var validfullName = fullName.trim()
    var validmsg = msg.trim()
    var validemail = regEmail.test(email)
    if(validemail && validfullName.length !== 0 && validmsg.length !== 0){
      return true
    }else{
      return false
    }
  }

  const onChange = (e) => {
    if(e.target.name === "fullname"){
      setFullName(e.target.value);
    }else if(e.target.name === "email"){
      setEmail(e.target.value)
    }else if(e.target.name === "subject"){
      setSubject(e.target.value)
    }else{
      setMsg(e.target.value)
    }
  }

  const onSubmit = async (e) => {
    e.preventDefault();
    setConfirmLoading(true)
    if(!isValid()){
      setSentEmailError(true)
      setConfirmLoading(false)
    }else{
      const temp = await sendEmail(fullName, email, subject, msg)
      setSentEmail(true) // api call
      setConfirmLoading(false)
    }
  };

  return (
    <Container>
      <TwoColumn>
        <ImageColumn>
          <Image imageSrc={EmailIllustrationSrc} />
        </ImageColumn>
        <TextColumn textOnLeft={textOnLeft}>
          <TextContent>
            {subheading && <Subheading>{subheading}</Subheading>}
            <Heading>{heading}</Heading>
            { !sentemail ?
              <Form onSubmit={onSubmit}>
                <Input onChange={onChange} type="text" name="fullname" placeholder="Full Name*" />
                <Input onChange={onChange} type="email" name="email" placeholder="Your Email Address*" />
                <Input onChange={onChange} type="text" name="subject" placeholder="Subject" />
                <Textarea onChange={onChange} name="message" placeholder="Your Message Here*" />
                <SubmitButton type="submit" >{submitButtonText}</SubmitButton>
                {sentemailerror ?
                <Description>{tryagain}</Description>
                :
                <Description></Description>
                }
              </Form>
              :
                <Form preserve={false}>
                <Description>{description}</Description>
                </Form>
            }

          </TextContent>
        </TextColumn>
      </TwoColumn>
    </Container>
  );
};


export default TwoColContactUsWithIllustrationFullForm;