import axios from 'axios'
const { API_URL } = require('./constants');

const addUser = async (email) => {

  let data = JSON.stringify({
    "email": email,
  });

  let config = {
    method: 'post',
    maxBodyLength: Infinity,
    url: API_URL+'addUser',
    headers: { 
      'Content-Type': 'application/json', 
      'Authorization': 'Basic YWRtaW46U3VwZXJTZWNyZXRQd2Q='
    },
    data : data
  };

  let result = ''

  await axios.request(config)
  .then((response) => {
    result = JSON.stringify(response.data)
  })
  .catch((error) => {
    return error;
  });

  return result

};

export default addUser;
