import React, { useState } from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";

import addUser from "components/api/addUser";
const Column = tw.div`px-4 sm:px-0 sm:w-1/4 md:w-auto mt-12`;

const ColumnHeading = tw.h5`uppercase font-bold`;

const SubscribeNewsletterColumn = tw(Column)`text-center lg:text-left w-full! lg:w-auto! mt-20 lg:mt-12`;
const SubscribeNewsletterContainer = tw.div`max-w-sm mx-auto lg:mx-0 `;
const SubscribeText = tw.p`mt-2 lg:mt-6 text-sm font-medium text-gray-600`;
const SubscribeForm = tw.form`mt-4 lg:mt-6 text-sm sm:flex max-w-xs sm:max-w-none mx-auto sm:mx-0`;
const Input = tw.input`bg-gray-100 px-6 py-3 rounded sm:rounded-r-none border-2 sm:border-r-0 border-gray-400 hover:border-primary-500 focus:outline-none transition duration-300 w-full`;
const SubscribeButton = tw(PrimaryButtonBase)`mt-4 sm:mt-0 w-full sm:w-auto rounded sm:rounded-l-none px-8 py-3`;
const PrimaryButton = tw(PrimaryButtonBase)`mt-8 inline-block w-56 tracking-wide text-center py-5`;

export const NavLink = tw.a`
  text-lg my-2 lg:text-sm lg:mx-6 lg:my-0
  font-semibold tracking-wide transition duration-300
  pb-1 border-b-2 border-transparent hover:border-amber-500 hocus:text-amber-600
`;

const NewsSubscribe = () => {

  const [email, setEmail] = useState('');
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [subscribe, setSubscribe] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");


  return (
          <SubscribeNewsletterColumn>
            <SubscribeNewsletterContainer>
              <ColumnHeading>Subscribe to our Newsletter</ColumnHeading>
              <SubscribeText>
                We deliver udpates written by professionals weekly. And we promise no spam.
              </SubscribeText>

              <SubscribeText>
                <b>{errorMsg}</b>
              </SubscribeText>
              <PrimaryButton as="a" target="_blank" rel="noreferrer" href="https://forms.gle/7oqFFi38EsN7VJes6">
                Subscribe
              </PrimaryButton>
            </SubscribeNewsletterContainer>
          </SubscribeNewsletterColumn>
  );
};

export default NewsSubscribe