import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components"; //eslint-disable-line
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";
import MainFeature1 from "components/features/TwoColWithButton.js";
// import MainFeature2 from "components/features/TwoColSingleFeatureWithStats.js";
// import MainFeature3 from "components/features/TwoColSingleFeatureWithStats2.js";
import Features from "components/features/ThreeColSimple.js";
// import Features from "components/features/ThreeColWithSideImage.js";
import TeamCardGrid from "components/cards/ProfileThreeColGrid.js";
import StockImage from "../images/stockprice.jpeg";
import StockImage2 from "../images/stockprice2.jpg";

// Alan Chen
const Subheading = tw.span`uppercase tracking-wider text-sm`;
export default () => {
  return (
    <AnimationRevealPage>
      <Header />
      <MainFeature1
        subheading={<Subheading>About Coeus Studio</Subheading>}
        heading="We are a financial alert design platform."
        buttonRounded={false}
        primaryButtonText="Get Started"
        primaryButtonUrl="https://www.coeus.space/"
        newTab={true}
        description="The accessibility of the stock market is on the rise, propelled by the proliferation of high-speed internet, enhanced computational capabilities, and the emergence of platforms like Robinhood and Webull. According to BusinessInsider (2021), one in five Americans are now actively participating in the stock market. Coeus Studio offers a platform tailored to everyday investors, empowering them to create sophisticated alert systems to support their investment endeavors."
        imageSrc={StockImage} // Width: 600 X Height: 400
      />
      <MainFeature1
        subheading={<Subheading>Our Vision</Subheading>}
        heading="We aim to disrupt the financial technology space."
        buttonRounded={false}
        primaryButtonText="See Examples"
        primaryButtonUrl="/use-cases"
        newTab={false}
        description="Coeus Studio builds technology that empowers the people to pick better stocks."
        imageSrc={StockImage2}
        textOnLeft={false}
      />
      <Features
        subheading={<Subheading>Our Values</Subheading>}
        heading="We follow these."
        description="
        We hold ourselves and each other accountable for demonstrating these principles through our actions every day. Our principles describe how Coeus Studio does business, how leaders lead, and how we keep the customer at the center of our decisions."
        cards={[
          {
            title: "Customer-Centricity",
            description: `We embrace "the customer is always right" motto which gives the highest priority to customer satisfaction.`,
          },
          {
            title: "Leadership",
            description:
              "Chain of command is important. A good leader can influence a person's beliefs, attitudes, intentions, motivations, or behaviours.",
          },
          {
            title: "Self-Improvement",
            description:
              "Never satisfy for mediocrity, instead strive for the best. Leverage failures into learning opportunities.",
          },
          {
            title: "Mission-Driven",
            description:
              "Live your life with a sense of purpose. We invest in our workers and communities while addressing shared challenges.",
          },
          {
            title: "Open-Minded",
            description:
              "Embrace change, be willing to consider ideas and opinions that are new or different to your own.",
          },
          {
            title: "Loyalty and Trust",
            description:
              "It can help built stronger bonds and create social support. It is important to be reliable and dependable.",
          },
          {
            title: "Deliver Actionable Results and Strategy",
            description:
              "Instead of just listing problems, figure out how to fix them by coming up with a step by step process.",
          },
          {
            title: "Innovate and Simplify",
            description:
              "We are consistently looking for new ideas everywhere. We challenge the normalcy and always find ways to simplify.",
          },
          {
            title: "Inspire and Empower",
            description:
              "Support and encourage others in order to colletively reach our fullest potential.",
          },
        ]}
        linkText=""
      />
      {/* <TeamCardGrid 
        subheading={<Subheading>Our Team</Subheading>}
      /> */}
      <Footer />
    </AnimationRevealPage>
  );
};
