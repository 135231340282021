import React from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";

import Hero from "components/hero/TwoColumnWithFeaturesAndTestimonial.js";
import Features from "components/features/ThreeColWithSideImage.js";
import VideoDemo from "components/features/VideoDemo.js";
import MainFeature from "components/features/TwoColWithTwoHorizontalFeaturesAndButton.js";
import Connections from "./Connections.js";
import FeatureStats from "components/features/ThreeColCenteredStatsPrimaryBackground.js";
import Pricing from "components/pricing/PricingPage.js";
import Blog from "components/blogs/GridWithFeaturedPost.js";
import Testimonial from "components/testimonials/TwoColumnWithImageAndRating.js";
import FAQ from "components/faqs/SingleCol.js";
import GetStarted from "components/cta/GetStartedLight.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";

const HighlightedText = tw.span`text-amber-500`;

export default () => {
  return (
    <AnimationRevealPage>
      <Hero />
      {/* <GetStarted/> */}
      {/* <FeatureStats/> */}
      <Connections
        heading={
          <>
            Coeus Studio connects to your{" "}
            <HighlightedText>apps</HighlightedText>
          </>
        }
      />
      <VideoDemo
        heading={
          <>
            Amazing <HighlightedText>Features Services</HighlightedText>
          </>
        }
      />
      <Features
        heading={
          <>
            Amazing <HighlightedText>Features Services</HighlightedText>
          </>
        }
      />
      <MainFeature
        heading={
          <>
            Coeus Studio built by and for{" "}
            <HighlightedText>investors</HighlightedText>
          </>
        }
      />
      <Footer />
    </AnimationRevealPage>
  );
};
