import React, { useState } from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import NewsSubscribe from "./NewsSubscribe";

import coeusLogo from "images/coeus-logo.png";
import { ReactComponent as FacebookIcon } from "images/facebook-icon.svg";
import { ReactComponent as TwitterIcon } from "images/twitter-icon.svg";
import { ReactComponent as YoutubeIcon } from "images/youtube-icon.svg";
import addUser from "components/api/addUser";
const Container = tw.div`relative bg-gray-200 text-gray-700 -mb-8 -mx-8 px-8 py-20 lg:py-24`;
const Content = tw.div`max-w-screen-xl mx-auto relative z-10`;
const SixColumns = tw.div`flex flex-wrap text-center sm:text-left justify-center sm:justify-start md:justify-between -mt-12`;

const Column = tw.div`px-4 sm:px-0 sm:w-1/4 md:w-auto mt-12`;

const ColumnHeading = tw.h5`uppercase font-bold`;

const LinkList = tw.ul`mt-6 text-sm font-medium`;
const LinkListItem = tw.li`mt-3`;
const Link = tw.a`border-b-2 border-transparent hocus:border-gray-700 pb-1 transition duration-300`;

const Divider = tw.div`my-16 border-b-2 border-gray-300 w-full`;

const ThreeColRow = tw.div`flex flex-col md:flex-row items-center justify-between`;

const LogoContainer = tw.div`flex items-center justify-center md:justify-start`;
const LogoImg = tw.img`w-8`;
const LogoText = tw.h5`ml-2 text-xl font-black tracking-wider text-gray-800`;

const CopywrightNotice = tw.p`text-center text-sm sm:text-base mt-8 md:mt-0 font-medium text-gray-500`;

const SocialLinksContainer = tw.div`mt-8 md:mt-0 flex`;
const SocialLink = styled.a`
  ${tw`cursor-pointer p-2 rounded-full bg-gray-900 text-gray-100 hover:bg-gray-700 transition duration-300 mr-4 last:mr-0`}
  svg {
    ${tw`w-4 h-4`}
  }
`;


const FiveColumnWithInputForm = () => {

  const [email, setEmail] = useState('');
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [subscribe, setSubscribe] = useState(false);

  const isValid = () => {
    var regEmail = /\S+@\S+\.\S+/;
    var validemail = regEmail.test(email)
    if(validemail){
      return true
    }else{
      return false
    }
  }

  const onChange = (e) => {
    if(e.target.placeholder == "Your Email Address"){
      setEmail(e.target.value)
    }
  }

  const onSubmit = async (e) => { // set api call right here
    setConfirmLoading(true)

    if(isValid()){
      e.preventDefault();
      const temp = await addUser(email) // no matter the outcome -> UI is a success (tell user)
      setSubscribe(true)
      setConfirmLoading(false)
    }else{
      e.preventDefault();
      setSubscribe(false)
      setConfirmLoading(false)
    }
  };

  return (
    <Container>
      <Content>
        <SixColumns>
          <Column>
            <ColumnHeading>Main</ColumnHeading>
            <LinkList>
              <LinkListItem>
                <Link href="/main/about-us">About Us</Link>
              </LinkListItem>
              <LinkListItem>
                <Link target="_blank" rel="noreferrer" href="https://www.coeus.space">Login</Link>
              </LinkListItem>
              {/* <LinkListItem>
                <Link href="/main/faqs">FAQs</Link>
              </LinkListItem> */}
              <LinkListItem>
                <Link href="/main/contact-us">Contact Us</Link>
              </LinkListItem>
            </LinkList>
          </Column>
          <Column>
            <ColumnHeading>Product</ColumnHeading>
            <LinkList>
              <LinkListItem>
                <Link href="/product/tutorials">Tutorials</Link>
              </LinkListItem>
              <LinkListItem>
                <Link href="/product/use-cases">Use Cases</Link>
              </LinkListItem>
              {/* <LinkListItem>
                <Link href="/product/pricing">Pricing</Link>
              </LinkListItem> */}
              <LinkListItem>
                <Link href="/product/documents">Documents</Link>
              </LinkListItem>
            </LinkList>
          </Column>
          <Column>
            <ColumnHeading>Press</ColumnHeading>
            <LinkList>
              <LinkListItem>
                <Link href="/press/logo">Logo</Link>
              </LinkListItem>
              {/* <LinkListItem>
                <Link href="/">Events</Link>
              </LinkListItem> */}
              <LinkListItem>
                <Link href="/press/testimonials">Testimonials</Link>
              </LinkListItem>
              <LinkListItem>
                <Link href="/">Office</Link>
              </LinkListItem>
            </LinkList>
          </Column>
          <Column>
            <ColumnHeading>Legal</ColumnHeading>
            <LinkList>
              <LinkListItem>
                <Link href="/">GDPR</Link>
              </LinkListItem>
              <LinkListItem>
                <Link href="/">Privacy Policy</Link>
              </LinkListItem>
              <LinkListItem>
                <Link href="/">Terms of Service</Link>
              </LinkListItem>
              {/* <LinkListItem>
                <Link href="/">Disclaimer</Link>
              </LinkListItem> */}
            </LinkList>
          </Column>
          <NewsSubscribe/>
        </SixColumns>
        <Divider />
        <ThreeColRow>
          <LogoContainer>
            <LogoImg src={coeusLogo} />
            <LogoText>Coeus Studio</LogoText>
          </LogoContainer>
          <CopywrightNotice>&copy; 2023 Coeus Studio All Rights Reserved.</CopywrightNotice>
          <SocialLinksContainer>
            {/* <SocialLink target="_blank" rel="noreferrer" href="https://www.facebook.com/profile.php?id=100095226039436">
              <FacebookIcon />
            </SocialLink> */}
            {/* <SocialLink target="_blank" rel="noreferrer" href="https://twitter.com">
              <TwitterIcon />
            </SocialLink>
            <SocialLink target="_blank" rel="noreferrer" href="https://youtube.com">
              <YoutubeIcon />
            </SocialLink> */}
          </SocialLinksContainer>
        </ThreeColRow>
      </Content>
    </Container>
  );
};

export default FiveColumnWithInputForm