import axios from 'axios'
const { API_URL } = require('./constants');

const sendEmail = async (fullname, email, subject, msg) => {

  console.log(email)
  let data = JSON.stringify({
  "name": fullname,
  "email": email,
  "subject": subject,
  "message": msg
});

  let config = {
    method: 'post',
    maxBodyLength: Infinity,
    url: API_URL+'sendEmail',
    headers: { 
      'Content-Type': 'application/json',
    },
    data : data
  };

  let result = ''

  await axios.request(config)
  .then((response) => {
    result = JSON.stringify(response.data)
  })
  .catch((error) => {
    return error;
  });

  return result

};

export default sendEmail;